import { Box, Burger, Container, Drawer, Group, Image, MantineTheme, Stack, Sx, UnstyledButton } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useDisclosure, useViewportSize } from "@mantine/hooks";
import { ReactNode } from "react";
import { MOBILE_BREAKPOINT_PX } from "../CONSTS";
import { ComponentSize } from "../TYPES";
import ButtonBase from "../inputs/buttons/ButtonBase";
import { Link } from "react-router-dom";

type Props = {
  buttons: ReactNode[];
  favicon: string;
  typeface?: string;

  gap?: ComponentSize;
  faviconLink?: string;
}

export default function NavbarBase(props: Props) {

  // Hooks
  const { t } = useTranslation();
  const { width } = useViewportSize();
  const isMobile = width < MOBILE_BREAKPOINT_PX;

  // States
  const [opened, { open, close }] = useDisclosure(false);

  // Styles
  const backgroundStyle: Sx = (theme: MantineTheme) => ({
    backgroundColor: theme.white,
    boxShadow: `0px 10px 30px 0px rgba(0,0,0,0.2)`,
    position: 'fixed',
    zIndex: 999,
    top: 0,
    left: 0,
    width: '100vw',
  });
  const containerStyle: Sx = (theme: MantineTheme) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing.md,
  });
  const buttonContainerStyle: Sx = (theme: MantineTheme) => ({
    gap: props.gap || theme.spacing.sm,
  });
  const buttonDrawerStyle: Sx = (theme: MantineTheme) => ({
    gap: props.gap || theme.spacing.sm,
    width: '100%',
    height: '100%',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })

  return (
    <>
      {/* Mobile drawer */}
      <Drawer
        opened={opened}
        onClose={close}
        position='bottom'
        withCloseButton={false}
        overlayProps={{ blur: 4 }}
        size='fit-content'
      >
        <Stack
          sx={buttonDrawerStyle}
        >
          {props.buttons}
        </Stack>
      </Drawer>

      {/* Navbar */}
      <Box sx={backgroundStyle}>
        <Container
          size='md'
          px='sm'
          sx={containerStyle}
        >
          {/* Favicon & typeface */}
          <UnstyledButton
            component={Link}
            to={props.faviconLink || '/'}
          >
            <Group>
              <Image
                src={props.favicon}
                height={30}
                width='auto'
              />

              {isMobile || !props.typeface ? null :
                <Image
                  src={props.typeface}
                  height={15}
                  width='auto'
                />
              }
            </Group>
          </UnstyledButton>

          {/* Buttons */}
          {isMobile ?
            <ButtonBase
              onClick={opened ? close : open}
              square
              component='div'
            >
              <Burger
                opened={opened}
              />
            </ButtonBase>
            :
            <Group sx={buttonContainerStyle}>
              {props.buttons}
            </Group>
          }
        </Container>
      </Box>
    </>
  )
}