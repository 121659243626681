import { Box, MantineTheme, Sx } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { MOBILE_BREAKPOINT_PX } from "../CONSTS";
import { BorderStyle, BreakpointVariant } from "../TYPES";

type LineProps = {
  color?: string;
  thickness?: number;
  style?: BorderStyle | string;

  marginHorizontal?: string | number;
  marginVertical?: string | number;

  height?: string | number;
  width?: string | number;
}
type AdaptiveLineProps = LineProps & {
  orientation: BreakpointVariant<'horizontal' | 'vertical'>;
}

const Line = function () {
  return (<></>)
}

Line.Vertical = function VerticalLine(props: LineProps) {

  // Style 
  const lineStyle: Sx = (theme: MantineTheme) => ({
    height: props.height,
    width: props.width || 1,

    borderLeft: `${props.thickness || 1}px ${props.style || 'solid'} ${props.color ?
      theme.colors[props.color][4] : theme.black
      }`,

    margin: `${props.marginVertical || 0}px ${props.marginHorizontal || 0}px ${props.marginVertical || 0}px ${props.marginHorizontal || 0}px`
  });

  return (
    <Box
      sx={lineStyle}
    />
  )
}

Line.Horizontal = function HorizontalLine(props: LineProps) {
  // Style 
  const lineStyle: Sx = (theme: MantineTheme) => ({
    height: props.height || 1,
    width: props.width,

    borderTop: `${props.thickness || 1}px ${props.style || 'solid'} ${props.color ?
      theme.colors[props.color][4] : theme.black
      }`,

    margin: `${props.marginVertical || 0}px ${props.marginHorizontal || 0}px ${props.marginVertical || 0}px ${props.marginHorizontal || 0}px`
  });

  return (
    <Box
      sx={lineStyle}
    />
  )
}

Line.Adaptive = function AdaptiveLine(props: AdaptiveLineProps) {

  const { width } = useViewportSize();
  const isMobile = width <= MOBILE_BREAKPOINT_PX;

  return (
    isMobile && props.orientation.mobile ?
      props.orientation.mobile === 'vertical' ?
        <Line.Vertical {...props} /> : <Line.Horizontal {...props} />
      :
      props.orientation.desktop === 'vertical' ?
        <Line.Vertical {...props} /> : <Line.Horizontal {...props} />
  )
}

export default Line;