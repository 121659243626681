import { Group } from "@mantine/core";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Text from "../../../phosphorus/text/FormattedText";
import CustomNumberInput from "../../inputs/CustomNumberInput";
import { Room, RoomQty } from "../../../func/models/Quote";

type Props = {
  room: Room;
  setSelectedRooms: Dispatch<SetStateAction<RoomQty[]>>;
}

export default function PCRow(props: Props) {

  // Hooks
  const { t } = useTranslation('pricingCalculator');

  // States
  const [qty, setQty] = useState(0);

  // Effects
  useEffect(() => {
    // When the quantity changes, update this on the SelectedSpaces list
    props.setSelectedRooms(prev => {
      // Attempt to find a spaceExt associated with this space
      let spaceExt = prev.find(e => e.room.name === props.room.name);
      if (spaceExt) prev.splice(prev.indexOf(spaceExt), 1);
      // Add a new space to the list
      return [
        ...prev,
        {
          room: props.room,
          qty: qty,
        }
      ];
    });
  }, [qty]);

  return (
    <Group
      style={{ flexWrap: 'nowrap' }}
    >
      <Text
        size='sm'
        style={{ flex: 1}}
      >
        {t(`steps.two.options.${props.room.name}`)}
      </Text>

      <CustomNumberInput
        value={qty}
        setValue={setQty}
      />
    </Group>
  )
}