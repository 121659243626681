import { CSSProperties, Dispatch, ReactNode, SetStateAction } from "react";

// GENERAL
export enum ComponentSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}
/** Apply this to a parameter to provide an interface for
 * both mobile and desktop variants of an object. Takes a 
 * lot of the load off of high-level elements
 */
export type BreakpointVariant<type> = {
  desktop: type;
  mobile?: type;
}
export type Gradient = {
  to: string;
  from: string;
  deg?: number;
}

// FLEX LAYOUT
export enum FlexDirection { 
  Vertical = 'column',
  Horizontal = 'row',
  Column = 'column',
  Row = 'row',
  VerticalReverse = 'column-reverse',
  HorizontalReverse = 'row-reverse',
  ColumnReverse = 'column-reverse',
  RowReverse = 'row-reverse',
}
export enum FlexAlign { 
  Start = 'flex-start',
  End = 'flex-end',
  Center = 'center',
  Stretch = 'stretch',
}
export enum FlexJustify { 
  Start = 'flex-start',
  End = 'flex-end',
  Center = 'center',
  Stretch = 'stretch',
  SpaceBetween = 'space-between',
  SpaceAround = 'space-around',
  SpaceEvenly = 'space-evenly',
}

// TEXT
export enum TextTransform {
  None = "none",
  Capitalize = "capitalize",
  Uppercase = "uppercase",
  Lowercase = "lowercase",
}
export enum TextAlign { 
  Left = 'left',
  Right = 'right',
  Center = 'center',
  Justify = 'justify',
}

// BUTTONS
export enum ButtonVariant {
  Filled = 'filled',
  Light = 'light',
  Subtle = 'subtle',
}

export type BaseButtonProps = { 
  /** The style variant of the button. Defaults to subtle */
  variant?: ButtonVariant;
  /** The size of the button. Defaults to the theme default */
  size?: ComponentSize;
  /** The radius of the button. Defaults to the theme default */
  radius?: ComponentSize;
  /** An optional width to supply the button. Accepts any CSS-accepted width property */
  width?: number | string;
  /** An optional height to supply the button. Accepts any CSS-accepted height property */
  height?: number | string;

  /** The background color of this button. Defaults to theme default color */
  backgroundColor?: any;
  /** The content color of this button */
  color?: any;
  
  /** A JS action to take upon clicking the button */
  onClick?: Function;
  /** Polymorphic content to allow this button to become another element */
  component?: any;
  /** Used by react-router-dom */
  to?: string;
  /** Used to link to external sites */
  href?: string;
  
  /** Whether this button is disabled */
  disabled?: boolean;
  /** Whether this button is a square */
  square?: boolean;
  /** Whether this button has a shadow */
  withShadow?: boolean;
  /** Whether this button has motion when hovered and tapped. This value is overridden if the user has reduced motion enabled */
  withMotion?: boolean;

  /** Any child objects of this button */
  children: ReactNode;
  /** Any additional style properties for this button */
  style?: CSSProperties;
}
export type TextButtonProps = BaseButtonProps & { 
  /** Text weight for this button's text */
  weight?: number;
  /** Text transform property for this button's text */
  transform?: TextTransform;
}

// LINES
export enum BorderStyle { 
  None = 'none',
  Hidden = 'hidden',
  Dotted = 'dotted',
  Dashed = 'dashed',
  Solid = 'solid',
  Double = 'double',
  Groove = 'groove',
  Inset = 'inset',
  Ridge = 'ridge',
  Outset = 'outset',
  Initial = 'initial',
  Inherit = 'inherit',
}

// IMAGES
export enum ImageFit { 
  Contain = 'contain',
  Cover = 'cover',
  Fill = 'fill',
  ScaleDown = 'scale-down',
  Inherit = 'inherit',
  Initial = 'initial',
  Revert = 'revert',
  Unset = 'unset',
  None = 'none',
}