import { ReactNode } from "react"
import { TextAlign } from "../TYPES";
import { MantineTheme, Sx, Title as BaseTitle, TitleOrder } from "@mantine/core";

type Props = {
  children: ReactNode;

  order?: TitleOrder;
  color?: string;
  size?: number | string;
  align?: TextAlign;

  shadow?: boolean;
}

/** A display title with custom sizing rules, etc. 
 * For semantic headers, use a Header element
 */
export default function DisplayTitle(props: Props) {

  // Styles
  const titleStyle: Sx = (theme: MantineTheme) => ({
    color: props.color ? theme.colors[props.color][4] : theme.black,
    fontFamily: 'Akira, sans-serif',
    lineHeight: 1,
    textShadow: !props.shadow ? null : `0px 10px 20px #00000050`, 
  });

  return (
    <BaseTitle
      order={props.order || 1}
      align={props.align}
      size={props.size || 60}
      sx={titleStyle}
    >
      {props.children}
    </BaseTitle>
  )
}