import { TFunction } from "i18next";
import Quote from "./models/Quote";

const MAILER_URL = 'https://mailing-server.zaccomode.workers.dev';
// const MAILER_URL = "http://localhost:8787";
const SITE_ID = 'af7c1fe6-d669-414e-b066-e9733f0de7a8';

export async function sendContactEmail(
  emailAddress: string,
  message: string,
) {
  let response = await fetch(`${MAILER_URL}/mailer/send`, {
    method: 'PUT',
    body: JSON.stringify({
      siteID: SITE_ID,
      email: emailAddress,
      message: message,
    }),
  });

  if (!response.ok) { 
    console.log('MAILER ERROR:', response.status, response.statusText);
    return false;
  }

  return true;
}

export async function sendQuoteEmail(
  emailAddress: string,
  quote: Quote,
  t: TFunction<any>,
) {
  let response = await fetch(`${MAILER_URL}/mailer/send`, {
    method: 'PUT',
    body: JSON.stringify({
      siteID: SITE_ID,
      email: emailAddress,
      message: quote.stringify(t),
    }),
  });

  if (!response.ok) { 
    console.log('MAILER ERROR:', response.status, response.statusText);
    return false;
  }

  return true;
}