import React, { CSSProperties, ReactElement } from "react"
import ButtonBase from "../../phosphorus/inputs/buttons/ButtonBase";
import Text from "../../phosphorus/text/FormattedText";

type Props = {
  icon: ReactElement;
  text: string;

  component: string;
  href: string;
}

export default function MassiveIconButton(props: Props) {

  // Icon
  const NewIcon = React.cloneElement(
    props.icon,
    {
      size: 100,
      stroke: 1,
      style: { position: 'relative' }
    },
    null
  )

  // Styles
  const buttonStyle: CSSProperties = { 
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    gap: 10,
  }

  return (
    <ButtonBase
      width='100%'
      height='100%'
      style={buttonStyle}
      
      component={props.component}
      href={props.href}
    >
      {NewIcon}

      <Text
        align='center'
      >
        {props.text}
      </Text>
    </ButtonBase>
  )
}