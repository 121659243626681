import { Center, Group, MantineTheme, Paper, Stack, Stepper, Sx } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { IconCurrencyDollar, IconDoor, IconHome, IconMail } from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MOBILE_BREAKPOINT_PX } from "../../../phosphorus/CONSTS";
import Line from "../../../phosphorus/display/Line";
import Button from "../../../phosphorus/inputs/buttons/Button";
import GradientButton from "../../../phosphorus/inputs/buttons/GradientButton";
import SegmentedControl from "../../../phosphorus/inputs/multi/SegmentedControl";
import Column from "../../../phosphorus/layout/columns/ColumnContainer";
import DisplayTitle from "../../../phosphorus/text/DisplayTitle";
import Text from "../../../phosphorus/text/FormattedText";
import { FlexDirection } from "../../../phosphorus/TYPES";
import { DEFAULT_GRADIENT } from "../../COLORS";
import PCRow from "./Row";
import Quote, { HOME_TYPES, JOINED_ROOMS, RoomQty, STANDALONE_ROOMS, getHomeType } from "../../../func/models/Quote";
import QuoteForm from "../forms/QuoteForm";

export default function PCMain() {

  // Hooks 
  const { t } = useTranslation('pricingCalculator');
  const { width } = useViewportSize();
  const isMobile = width <= MOBILE_BREAKPOINT_PX;

  // States
  const [activeStep, setActiveStep] = useState(0);
  const nextStep = () => setActiveStep((current) => (current < 2 ? current + 1 : current));
  const prevStep = () => setActiveStep((current) => (current > 0 ? current - 1 : current));

  const [homeType, setHomeType] = useState("house");
  const [selectedRooms, setSelectedRooms] = useState<RoomQty[]>([]);


  // Functions
  function validateStep() {
    if (activeStep === 0) return true;
    if (activeStep === 1) {
      if (createQuote().price > 0) return true;
      else return false;
    }

    return false;
  }

  function createQuote() {
    const quote = new Quote(
      getHomeType(homeType),
      selectedRooms,
    );
    return quote.calcTotal();
  }


  // Styles
  const stepperStyle: Sx = (theme: MantineTheme) => ({
    '& .mantine-Stepper-steps': {
      borderRadius: theme.radius.md,
      background: `${theme.colors.black[0]}20`,
      padding: theme.spacing.sm,
    }
  });


  return (
    <Stack spacing={20}>
      <DisplayTitle
        order={2}
        size={40}
      >
        {t('title')}
      </DisplayTitle>

      <Paper
        radius='lg'
        shadow='md'
        p='md'
      >
        <Stack spacing={10}>

          <Stepper
            active={activeStep}
            onStepClick={setActiveStep}
            breakpoint='sm'
            sx={stepperStyle}
            allowNextStepsSelect={false}
            radius="md"
            size="sm"
          >
            {/* Starting point */}
            <Stepper.Step
              label={t('steps.one.label')}
              description={t('steps.one.description')}
              icon={<IconHome />}
            >
              <Center>
                <Stack
                  spacing={10}
                  w={600}
                >
                  <SegmentedControl
                    data={Object.keys(HOME_TYPES).map((e) => {
                      return { label: t(`steps.one.options.${e}.label`), value: e }
                    })}
                    value={homeType}
                    onChange={setHomeType}
                    fullWidth
                  />

                  <Text
                    size='sm'
                    align='center'
                  >
                    {t(`steps.one.options.${homeType}.description`)}
                  </Text>
                </Stack>
              </Center>
            </Stepper.Step>

            {/* Room selection */}
            <Stepper.Step
              label={t('steps.two.label')}
              description={t('steps.two.description')}
              icon={<IconDoor />}
            >
              <Column.Container
                direction={{ desktop: FlexDirection.Row, mobile: FlexDirection.Column }}
              >
                {/* Joined rooms */}
                <Column
                  width={{ desktop: '50%', mobile: '100%' }}
                >
                  <Stack spacing={0}>
                    <Text size='lg' weight={800}>
                      {t('steps.two.types.joined.title')}
                    </Text>
                    <Text size='sm'>
                      {t('steps.two.types.joined.description')}
                    </Text>
                  </Stack>

                  {JOINED_ROOMS.map((r) =>
                    <PCRow
                      key={r.name}
                      room={r}
                      setSelectedRooms={setSelectedRooms}
                    />
                  )}
                </Column>

                <Line.Adaptive
                  orientation={{ desktop: 'vertical', mobile: 'horizontal' }}
                  marginHorizontal={isMobile ? 0 : 5}
                  marginVertical={isMobile ? 20 : 0}
                />

                {/* Standalone rooms */}
                <Column
                  width={{ desktop: '50%', mobile: '100%' }}
                >
                  <Stack spacing={0}>
                    <Text size='lg' weight={800}>
                      {t('steps.two.types.standalone.title')}
                    </Text>
                    <Text size='sm'>
                      {t('steps.two.types.standalone.description')}
                    </Text>
                  </Stack>

                  {STANDALONE_ROOMS.map((r) =>
                    <PCRow
                      key={r.name}
                      room={r}
                      setSelectedRooms={setSelectedRooms}
                    />
                  )}
                </Column>
              </Column.Container>
            </Stepper.Step>

            {/* Pricing estimate */}
            <Stepper.Step
              label={t('steps.three.label')}
              description={t('steps.three.description')}
              icon={<IconCurrencyDollar />}
            >
              <Center>
                <Stack spacing={10} w={400}>
                  <Text
                    align='center'
                    size='sm'
                  >
                    {t('steps.three.total')}
                  </Text>
                  <Text
                    isMonospace
                    gradient={DEFAULT_GRADIENT}
                    align='center'
                    size={40}
                  >
                    ${createQuote().price}.00
                  </Text>

                  <Text
                    align='center'
                    size='sm'
                  >
                    {t('steps.three.savings')}
                  </Text>

                  <Text
                    isMonospace
                    gradient={DEFAULT_GRADIENT}
                    align='center'
                    size={40}
                  >
                    ${createQuote().saving}.00
                  </Text>

                  {HOME_TYPES[homeType].discount === 0 ? null :
                    <Text
                      align='center'
                      size='sm'
                    >
                      {t('steps.three.discount', { discount: HOME_TYPES[homeType].discount })}
                    </Text>
                  }

                  {/* Contact form */}
                  <QuoteForm quote={new Quote(getHomeType(homeType), selectedRooms)} />
                </Stack>
              </Center>
            </Stepper.Step>
          </Stepper>

          {/* Navigation buttons */}
          <Group position='center' mt='xl' spacing={10}>
            <Button
              onClick={prevStep}
              disabled={activeStep === 0}
            >
              {t('navButtons.back')}
            </Button>
            <GradientButton
              gradient={DEFAULT_GRADIENT}
              onClick={nextStep}
              disabled={!validateStep()}
            >
              {t('navButtons.forward')}
            </GradientButton>
          </Group>
        </Stack>
      </Paper>

      {/* Disclaimer */}
      <Text
        size='xs'
        align='center'
        opacity={0.5}
      >
        {t('disclaimer')}
      </Text>
    </Stack>
  )
}