import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: "en",
  resources: {
    en: {
      common:               require("./locales/en/common.json"),
      home:                 require("./locales/en/pages/home.json"),

      pricingCalculator:    require("./locales/en/components/pricingCalculator.json"),
      mailingForms:         require("./locales/en/components/mailingForms.json"),
    },
  },
  ns: [
    "common",
    "home",
    "pricingCalculator",
    "mailingForms",
  ],
  defaultNS: "common"
});

i18n.languages = ["en"];

export default i18n;