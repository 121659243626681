import { useViewportSize } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { MOBILE_BREAKPOINT_PX } from "../../phosphorus/CONSTS";
import Button from "../../phosphorus/inputs/buttons/Button";
import GradientButton from "../../phosphorus/inputs/buttons/GradientButton";
import NavbarBase from "../../phosphorus/layout/NavbarBase";
import { ComponentSize } from "../../phosphorus/TYPES";
import { DEFAULT_GRADIENT } from "../COLORS";

import BrandType from "../../assets/images/typeface.png";

export default function Navbar() {

  // Hooks
  const { t } = useTranslation();
  const { width } = useViewportSize();
  const isMobile = width <= MOBILE_BREAKPOINT_PX;

  return (
    <NavbarBase
      favicon={BrandType}
      buttons={[
        <Button
          key="pricing"
          width={isMobile ? '100%' : null}
          size={isMobile ? ComponentSize.MD : null}

          component='a'
          href='#pricing'
        >
          {t('navButtons.pricing')}
        </Button>,

        <Button
          key="about"
          width={isMobile ? '100%' : null}
          size={isMobile ? ComponentSize.MD : null}

          component='a'
          href='#about'
        >
          {t('navButtons.about')}
        </Button>,

        <GradientButton
          key="contact"
          width={isMobile ? '100%' : null}
          size={isMobile ? ComponentSize.MD : null}
          gradient={DEFAULT_GRADIENT}
          withShadow

          component='a'
          href='#contact'
        >
          {t('navButtons.contact')}
        </GradientButton>
      ]}
    />
  )
}