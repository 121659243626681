import { MantineTheme, Sx, TextInput as TI } from "@mantine/core";
import { CSSProperties, Dispatch, ReactNode, SetStateAction } from "react";
import { ComponentSize } from "../../TYPES";

export enum AutoCapitalize { 
  Off = 'off',
  On = 'on',
  Words = 'words',
  Characters = 'characters',
}
export enum AutoComplete { 
  Off = 'off',
  On = 'on',
  Name = 'name',
  HonorificPrefix = 'honorific-prefix',
  HonorificSuffix = 'honorific-suffix',
  GivenName = 'given-name',
  AdditionalName = 'additional-name',
  FamilyName = 'family-name',
  Nickname = 'nickname',
  Email = 'email',
  Username = 'username',
  NewPassword = 'new-password',
  CurrentPassword = 'current-password',
  OneTimeCode = 'one-time-code',
  OrganizationTitle = 'organization-title',
  Organization = 'organization',
  StreetAddress = 'street-address',
  AddressLine1 = 'address-line1',
  AddressLine2 = 'address-line2',
  AddressLine3 = 'address-line3',
  AddressLevel4 = 'address-level4',
  AddressLevel3 = 'address-level3',
  AddressLevel2 = 'address-level2',
  AddressLevel1 = 'address-level1',
  Country = 'country',
  CountryName = 'country-name',
  PostalCode = 'postal-code',
  CCName = 'cc-name',
  CCGivenName = 'cc-given-name',
  CCAdditionalName = 'cc-additional-name',
  CCFamilyName = 'cc-family-name',
  CCNumber = 'cc-number',
  CCExp = 'cc-exp',
  CCExpMonth = 'cc-exp-month',
  CCExpYear = 'cc-exp-year',
  CCCsc = 'cc-csc',
  CCType = 'cc-type',
  TransactionCurrency = 'transaction-currency',
  TransactionAmount = 'transaction-amount',
  Language = 'language',
  Bday = 'bday',
  BdayDay = 'bday-day',
  BdayMonth = 'bday-month',
  BdayYear = 'bday-year',
  Sex = 'sex',
  Tel = 'tel',
  TelCountryCode = 'tel-country-code',
  TelNational = 'tel-national',
  TelAreaCode = 'tel-area-code',
  TelExtension = 'tel-extension',
  Impp = 'impp',
  Url = 'url',
  Photo = 'photo',
}

export type TextInputProps = { 
  placeholder: string;
  label?: string;
  
  icon?: ReactNode;
  iconWidth?: string | number;

  value?: string;
  setValue?: Dispatch<SetStateAction<string>>;
  onEnterPress?: Function;

  maxLength?: number;
  disabled?: boolean;
  
  radius?: ComponentSize;
  size?: ComponentSize;
  
  autoFocus?: boolean;
  autoCapitalize?: AutoCapitalize | string;
  autoComplete?: AutoComplete | string;
  autoCorrect?: boolean;

  style?: CSSProperties;
}

export default function TextInput(props: TextInputProps) { 

  // Event handlers
  const handleKeyDown = (event: any) => { 
    if (props.onEnterPress && event.key === 'Enter') props.onEnterPress();
  }

  // Styles
  const inputStyle: Sx = (theme: MantineTheme) => ({
    '& > * > .mantine-Input-input': { 
      backgroundColor: `${theme.black}19`,
      color: theme.black,
      border: 'none',
      '::placeholder': { 
        color: `${theme.black}55`,
      },
      '&:focus': { 
        outline: `1px solid ${theme.black}`
      }
    }
  });

  return ( 
    <TI
      variant='filled'
      placeholder={props.placeholder}
      label={props.label}
      
      icon={props.icon}
      iconWidth={props.iconWidth}

      value={props.value}
      onChange={(event) => props.setValue(event.currentTarget.value)}
      onKeyDown={handleKeyDown}

      maxLength={props.maxLength}
      disabled={props.disabled}
      
      autoFocus={props.autoFocus}
      autoCapitalize={props.autoCapitalize}
      autoComplete={props.autoComplete}
      autoCorrect={props.autoCorrect ? 'on' : 'off'}

      sx={inputStyle}
      style={props.style}
    />
  )
}