import { Image as BaseImage, MantineTheme, Sx } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { ReactNode } from "react";
import { MOBILE_BREAKPOINT_PX } from "../CONSTS";
import { BreakpointVariant, ComponentSize, ImageFit } from "../TYPES";

type Props = {
  src: string;
  alt: string;

  height?: BreakpointVariant<string | number>;
  width?: BreakpointVariant<string | number>;
  radius?: ComponentSize;
  placeholder?: ReactNode;
  clipPath?: BreakpointVariant<string>;
  fit?: ImageFit;
}

export default function Image(props: Props) {

  // Hooks
  const { width } = useViewportSize();
  const isMobile = width <= MOBILE_BREAKPOINT_PX;

  // Styles
  const imgStyle: Sx = (theme: MantineTheme) => ({
    clipPath: !props.clipPath ? null : isMobile && props.clipPath.mobile ? 
      props.clipPath.mobile : props.clipPath.desktop,
  });

  return (
    <BaseImage
      src={props.src}
      alt={props.alt}

      width={!props.width ? null : isMobile && props.width.mobile ?
        props.width.mobile : props.width.desktop
      }
      height={!props.height ? null : isMobile && props.height.mobile ?
        props.height.mobile : props.height.desktop
      }

      fit={props.fit}
      radius={props.radius}

      withPlaceholder
      placeholder={props.placeholder}

      sx={imgStyle}
    />
  )
}