import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Quote from "../../../func/models/Quote"
import { EMAIL_REGEX } from "./_types";
import { sendQuoteEmail } from "../../../func/mailer";
import { Group, Loader, MantineTheme, Stack, Sx } from "@mantine/core";
import { IconMail } from "@tabler/icons-react";
import TextInput, { AutoComplete } from "../../../phosphorus/inputs/text/TextInput";
import Text from "../../../phosphorus/text/FormattedText";
import GradientButton from "../../../phosphorus/inputs/buttons/GradientButton";
import { DEFAULT_GRADIENT } from "../../COLORS";

type Props = {
  quote: Quote;
}

export default function QuoteForm(props: Props) {

  // Hooks
  const { t } = useTranslation("mailingForms");
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  // States
  const [email, setEmail] = useState("");

  // Effects
  useEffect(() => {
    setEmailSent(sessionStorage.getItem("emailSent") === "true" ? true : false);
  }, []);

  // Send the email
  async function sendEmail() {
    let em = email.trim();

    // Ensure an email hasn"t already been sent
    if (emailSent) return;

    // Validate inputs
    if (!em) {
      setAlert(t("alerts.noInputs"));
      return;
    }
    if (!EMAIL_REGEX.test(em)) {
      setAlert(t("alerts.invalidEmail"));
      return;
    }

    setAlert("");
    setLoading(true);

    // Send email
    let response = await sendQuoteEmail(em, props.quote, t);
    setLoading(false);
    if (!response) {
      setAlert(t("alerts.error"));
      return;
    }

    setAlert(t("alerts.sent"))

    sessionStorage.setItem("emailSent", "true");
    setEmailSent(true);
  }

  const stackStyle: Sx = (theme: MantineTheme) => ({
    borderTop: `1px solid ${theme.colors.black[0]}`,
    paddingTop: theme.spacing.lg,
  });

  return (
    <Stack
      spacing="xs"
      align="flex-start"
      sx={stackStyle}
    >
      <Text size="sm" opacity={0.8} align="center">
        {t("steps.three.contact.label", { ns: "pricingCalculator" })}
      </Text>

      <TextInput
        placeholder={t("emailInput")}
        icon={<IconMail />}
        autoComplete={AutoComplete.Email}
        style={{ width: "100%" }}
        disabled={loading}

        value={email}
        setValue={setEmail}
        onEnterPress={sendEmail}
      />

      <Group>
        <GradientButton
          gradient={DEFAULT_GRADIENT}
          onClick={sendEmail}
          disabled={loading || emailSent}
        >
          {t("sendButton")}
        </GradientButton>

        <Text size="sm">
          {alert}
        </Text>

        {!loading ? null : <Loader size="sm" />}
      </Group>
    </Stack>
  )
}