import { MantineTheme, SegmentedControl as SC, SegmentedControlItem, Sx } from "@mantine/core";
import { CSSProperties, Dispatch, SetStateAction } from "react";
import { ComponentSize } from "../../TYPES";

type Props = {
  data: string[] | SegmentedControlItem[];
  value: string;
  onChange: Dispatch<SetStateAction<string>>;

  radius?: ComponentSize | string | number;
  size?: ComponentSize | string;
  color?: string;

  fullWidth?: boolean;
  disabled?: boolean;

  style?: CSSProperties;
}

export default function SegmentedControl(props: Props) {

  // Styles
  const scStyle: Sx = (theme: MantineTheme) => ({
    backgroundColor: `${theme.colors.black[0]}20`,
  });

  return (
    <SC
      data={props.data}
      value={props.value}
      onChange={props.onChange}

      radius={props.radius || 'xl'}
      size={props.size}
      color={props.color || 'black'}

      fullWidth={props.fullWidth}
      disabled={props.disabled}
      
      sx={scStyle}
      style={props.style}
    />
  )
}