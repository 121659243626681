import { TFunction } from "i18next";

export const HOME_TYPES = {
  house: { discount: 0 },
  apartment: { discount: 10 },
}
export const STANDALONE_ROOMS: Room[] = [
  { name: 'bathroom', price: 100, saving: 0 },
  { name: 'toilet', price: 60, saving: 0 },
  { name: 'laundry', price: 80, saving: 0 },
  { name: 'kitchen', price: 100, saving: 0 },
  { name: 'dining', price: 60, saving: 0 },
  { name: 'livingMedia', price: 60, saving: 0 },
  { name: 'bedroom', price: 60, saving: 0 },
];
export const JOINED_ROOMS: Room[] = [
  { name: 'toiletBathroom', price: 120, saving: 40 },
  { name: 'laundryBathroom', price: 150, saving: 30 },
  { name: 'kitchenDining', price: 130, saving: 30 },
  { name: 'kitchenLivingDining', price: 170, saving: 50 },
  { name: 'bedroomEnsuite', price: 130, saving: 30 },
  { name: 'bedroomEnsuiteCloset', price: 170, saving: 30 },
];



export default class Quote {
  homeType: HomeType;
  rooms: RoomQty[];

  constructor(
    homeType: HomeType,
    rooms: RoomQty[],
  ) {
    this.homeType = homeType;
    this.rooms = rooms;
  }

  calcTotal() {
    let subtotal = this.rooms.reduce((acc, cur) => {
      return acc + (cur.room.price * cur.qty)
    }, 0);
    let discount = HOME_TYPES[this.homeType].discount;

    let total = discount > 0 ? subtotal - (subtotal / discount) : subtotal;

    return {
      price: total,
      saving: this.rooms.reduce((acc, cur) => {
        return acc + (cur.room.saving * cur.qty)
      }, 0),
    };
  }

  stringify(t: TFunction<any>) {
    let total = this.calcTotal();

    let text = "Estimated quote value:";
    text += `\n- Building type: ${this.homeType}`
    text += `\n- Total cost: $${total.price}`;
    text += `\n- Total saving: $${total.saving}`;

    text += `\n\nRooms:`;
    text += this.rooms.reduce((a, r) => {
      if (r.qty < 1) return a + "";
      return a + `\n- ${t(`steps.two.options.${r.room.name}`, { ns: "pricingCalculator" })
        } | ${r.qty}x @ $${r.room.price} each | $${r.room.saving} saving`;
    }, "");

    return text;
  }
}

export enum HomeType {
  House = "house",
  Apartment = "apartment",
}
export function getHomeType(type: string): HomeType {
  //@ts-ignore
  let enumKey = Object.keys(HomeType)[Object.values(HomeType).indexOf(type)];
  return HomeType[enumKey];
}

export type Room = {
  name: string;
  price: number;
  saving: number;
}
export type RoomQty = {
  room: Room;
  qty: number;
}