import { MantineTheme, Sx, Textarea as TA } from "@mantine/core";
import { TextInputProps } from "./TextInput";

export type TextareaProps = TextInputProps & { 
  autosize?: boolean;
  maxRows?: number;
  minRows?: number;
}

export function Textarea(props: TextareaProps) { 

  // Event handlers
  const handleKeyDown = (event: any) => { 
    if (props.onEnterPress && event.key === 'Enter') props.onEnterPress();
  }

  // Styles
  const inputStyle: Sx = (theme: MantineTheme) => ({
    '& > * > .mantine-Input-input': { 
      backgroundColor: `${theme.black}19`,
      color: theme.black,
      border: 'none',
      '::placeholder': { 
        color: `${theme.black}55`,
      },
      '&:focus': { 
        outline: `1px solid ${theme.black}`
      }
    }
  });

  return ( 
    <TA
      variant='filled'
      placeholder={props.placeholder}
      label={props.label}
      
      icon={props.icon}
      iconWidth={props.iconWidth}

      value={props.value}
      onChange={(event) => props.setValue(event.currentTarget.value)}
      onKeyDown={handleKeyDown}

      maxLength={props.maxLength}
      disabled={props.disabled}
      
      autoFocus={props.autoFocus}
      autoCapitalize={props.autoCapitalize}
      autoComplete={props.autoComplete}
      autoCorrect={props.autoCorrect ? 'on' : 'off'}

      sx={inputStyle}
      style={props.style}

      autosize={props.autosize}
      maxRows={props.maxRows}
      minRows={props.minRows}
    />
  )
}