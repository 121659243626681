import akiraSuperBold from './assets/fonts/Akira-Super-Bold.otf';
import unboundedRegular from './assets/fonts/Unbounded-Regular.ttf';
import unboundedBold from './assets/fonts/Unbounded-Bold.ttf';
import jetBrainsMonoExtraBold from './assets/fonts/JetBrainsMono-ExtraBold.ttf';

import { Global, MantineProvider } from "@mantine/core";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { WHITE_PALETTE } from './phosphorus/COLORS';
import HomePage from './pages/Home';
import { NAVY_PALETTE } from './components/COLORS';

function App() {
  return (
    <>
      {/* Font imports */}
      <Global
        styles={[
          {
            '@font-face': {
              fontFamily: 'Unbounded',
              src: `url('${unboundedRegular}') format("woff2")`,
              fontWeight: 400,
              fontStyle: 'normal',
            },
          },
          {
            '@font-face': {
              fontFamily: 'Unbounded',
              src: `url('${unboundedBold}') format("woff2")`,
              fontWeight: 800,
              fontStyle: 'normal',
            },
          },
          {
            '@font-face': {
              fontFamily: 'Akira',
              src: `url('${akiraSuperBold}') format("woff2")`,
              fontWeight: 800,
              fontStyle: 'normal',
            },
          },
          {
            '@font-face': {
              fontFamily: 'JetBrains Mono',
              src: `url('${jetBrainsMonoExtraBold}') format("woff2")`,
              fontWeight: 800,
              fontStyle: 'normal',
            },
          },
        ]}
      />

      <BrowserRouter>
        {/* Global Styles */}
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={{
            fontFamily: 'Unbounded, sans-serif',
            fontFamilyMonospace: 'JetBrains Mono, monospace',
            // headings: { fontFamily: 'Akira, sans-serif', fontWeight: 800 },
            colors: {
              //@ts-ignore
              black: NAVY_PALETTE,
              //@ts-ignore
              white: WHITE_PALETTE,
            },
            black: NAVY_PALETTE[0],
            white: WHITE_PALETTE[0],
            primaryColor: 'black',
            primaryShade: 4,
            defaultRadius: 'md',
          }}
        >
          {/* Routes */}
          <Routes>
            <Route path='/' element={<HomePage />} />
          </Routes>
        </MantineProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
