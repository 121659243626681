// Images
import Typeface from '../../assets/images/typeface-white.png';

import { Image, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import PageSection from "../../phosphorus/layout/sections/PageSection";

export default function Footer() {

  // Hooks
  const { t } = useTranslation();

  return (
    <PageSection
      backgroundColor='black'
      style={{ 
        paddingTop: 100, 
        paddingBottom: 100, 
        alignItems: 'center',
        gap: 20,
      }}
    >
      <Image
        src={Typeface}
        alt="Right the First Time brand"
        height={50}
        width='auto'
      />

      <Text
        size='sm'
        align='center'
        color='white'
      >
        {t('footer.text')}
      </Text>
    </PageSection>
  )
}