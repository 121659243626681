import { useRef, Dispatch, SetStateAction } from 'react';
import { NumberInput, Group, ActionIcon, NumberInputHandlers, rem } from '@mantine/core';
import { DEFAULT_GRADIENT } from '../COLORS';

type Props = {
  value: number | '';
  setValue: Dispatch<SetStateAction<number | ''>>;

  min?: number;
  max?: number;
  step?: number;
}

export default function CustomNumberInput(props: Props) {
  const handlers = useRef<NumberInputHandlers>();

  return (
    <Group spacing={5}>
      <ActionIcon
        size={35}
        variant='gradient'
        gradient={DEFAULT_GRADIENT}
        onClick={() => handlers.current.decrement()}
        disabled={props.value <= props.min || props.value <= 0}
      >
        –
      </ActionIcon>

      <NumberInput
        hideControls
        value={props.value}
        onChange={(val) => props.setValue(val)}
        handlersRef={handlers}
        max={props.max || 10}
        min={props.min || 0}
        step={props.step}
        styles={{
          input: {
            width: rem(54),
            textAlign: 'center',
            fontFamily: 'JetBrains Mono, monospace'
          }
        }}
      />

      <ActionIcon
        size={35}
        variant='gradient'
        gradient={DEFAULT_GRADIENT}
        onClick={() => handlers.current.increment()}
        disabled={props.value >= props.max || props.value >= 10}
      >
        +
      </ActionIcon>
    </Group>
  );
}