import { useMantineTheme } from "@mantine/core";
import { ButtonVariant, TextButtonProps } from "../../TYPES";
import Button from "./Button";

type Props = TextButtonProps & {
  gradient: {
    to: string,
    from: string,
    deg?: number,
  };
}

export default function GradientButton(props: Props) {

  const theme = useMantineTheme();

  return (
    <Button
      {...props}
      style={{
        backgroundImage: `linear-gradient(${props.gradient.deg || 45}deg, ${theme.colors[props.gradient.from][4]
          }, ${theme.colors[props.gradient.to][4]
          })`
      }}
      variant={ButtonVariant.Filled}
      backgroundColor={props.gradient.from}
      withMotion
    >
      {props.children}
    </Button>
  )
}