import HeroImage from "../assets/images/misc/hero-image.jpg";
import Interior1 from "../assets/images/misc/interior-1.png";
import Interior2 from "../assets/images/misc/interior-2.png";

import Navbar from "../components/layout/Navbar";
import HeroSection from "../phosphorus/layout/sections/HeroSection";
import DisplayTitle from "../phosphorus/text/DisplayTitle";
import { useTranslation } from "react-i18next";
import Text from "../phosphorus/text/FormattedText";
import GradientButton from "../phosphorus/inputs/buttons/GradientButton";
import { FlexAlign, FlexDirection, FlexJustify, TextAlign } from "../phosphorus/TYPES";
import PageSection from "../phosphorus/layout/sections/PageSection";
import { DEFAULT_GRADIENT } from "../components/COLORS";
import { MOBILE_BREAKPOINT_PX } from "../phosphorus/CONSTS";
import Column from "../phosphorus/layout/columns/ColumnContainer";
import Image from "../phosphorus/display/Image";
import Line from "../phosphorus/display/Line";
import MassiveIconButton from "../components/inputs/MassiveIconButton";
import { IconMail, IconPhoneCheck } from "@tabler/icons-react";
import ContactForm from "../components/display/forms/ContactForm";
import Footer from "../components/layout/Footer";
import { useViewportSize } from "@mantine/hooks";
import PCMain from "../components/display/PricingCalculator/Main";

const ARROW_OFFSET = 90;

export default function HomePage() {

  // Hooks
  const { t } = useTranslation('home');
  const { width } = useViewportSize();
  const isMobile = width <= MOBILE_BREAKPOINT_PX;

  return (
    <>
      <Navbar />

      {/* Hero */}
      <a id="home" />
      <HeroSection
        backgroundImage={HeroImage}
        backgroundPosition={{
          desktop: 'center left 300px',
          mobile: 'center',
        }}
        clipPath={{
          desktop: 'polygon(40% 0, 100% 0, 100% 100%, 40% 100%, min(calc(40% + 320px), 100%) 50%)',
          mobile: 'none',
        }}
        backgroundOverlay={{
          desktop: { to: '#00000000', from: '#00000000' },
          mobile: { to: '#00000080', from: '#00000000', deg: 180 },
        }}
      >
        <Column.Container grow>
          <Column
            width={{ desktop: '60%', mobile: '100%' }}
            height={{ desktop: '100%' }}
            align={{ desktop: FlexAlign.Start }}
            justify={{ desktop: FlexJustify.Center, mobile: FlexJustify.End }}
          >
            <DisplayTitle
              color={isMobile ? 'white' : null}
              shadow={isMobile}
              size={isMobile ? 50 : null}
            >
              {t('hero.title')}
            </DisplayTitle>

            <Text
              color={isMobile ? 'white' : null}
            >
              {t('hero.subtitle')}
            </Text>

            <GradientButton
              gradient={DEFAULT_GRADIENT}
              withShadow
              component='a'
              href='#contact'
            >
              {t('navButtons.contact', { ns: 'common' })}
            </GradientButton>
          </Column>
          <Column
            grow={1}
            width={{ desktop: '40%', mobile: '0%' }}
            height={{ desktop: '0%' }}
          >
          </Column>
        </Column.Container>
      </HeroSection>

      {/* Pricing calculator */}
      <a id="pricing" />
      <PageSection
        paddingVertical={40}
        backgroundColor="black"
        backgroundOpacity="10"
        gap={20}
      >
        {/* <PricingCalculator /> */}
        <PCMain />
      </PageSection>

      <a id="about" />
      <PageSection
        paddingVertical={40}
        gap={60}
      >
        {/* About us */}
        <Column.Container
          direction={{ desktop: FlexDirection.Row, mobile: FlexDirection.Column }}
          grow
        >
          <Column
            width={{ desktop: '60%', mobile: '100%' }}
            align={{ desktop: FlexAlign.Start }}
            justify={{ desktop: FlexJustify.Center }}
          >
            <DisplayTitle
              order={2}
              size={40}
            >
              {t('about.title')}
            </DisplayTitle>

            <Text>
              {t('about.text')}
            </Text>
          </Column>

          <Column
            width={{ desktop: '40%', mobile: '100%' }}
            align={{ desktop: FlexAlign.End }}
          >
            <Image
              src={Interior1}
              alt='A photo of the interior of a clean apartment, overlooking a large city.'
              clipPath={{
                desktop: `polygon(0 0, calc(100% - ${ARROW_OFFSET}px) 0, 100% 50%, calc(100% - ${ARROW_OFFSET}px) 100%, 0 100%, ${ARROW_OFFSET}px 50%)`,
              }}
            />
          </Column>
        </Column.Container>

        {/* Quality guarantee */}
        <Column.Container
          direction={{ desktop: FlexDirection.Row, mobile: FlexDirection.ColumnReverse }}
          grow
        >
          <Column
            width={{ desktop: '40%', mobile: '100%' }}
            align={{ desktop: FlexAlign.End }}
          >
            <Image
              src={Interior2}
              alt='A photo of a clean interior living room with a large window overlooking a peninsula.'
              clipPath={{
                desktop: `polygon(${ARROW_OFFSET}px 0, 100% 0, calc(100% - ${ARROW_OFFSET}px) 50%, 100% 100%, ${ARROW_OFFSET}px 100%, 0 50%)`,
              }}
            />
          </Column>
          <Column
            width={{ desktop: '60%', mobile: '100%' }}
            align={{ desktop: FlexAlign.End }}
            justify={{ desktop: FlexJustify.Center }}
          >
            <DisplayTitle
              order={2}
              size={40}
              align={isMobile ? TextAlign.Left : TextAlign.Right}
            >
              {t('quality.title')}
            </DisplayTitle>

            <Text
              align={isMobile ? 'left' : 'right'}
            >
              {t('quality.text')}
            </Text>
          </Column>
        </Column.Container>
      </PageSection>

      {/* Contact section */}
      <a id="contact" />
      <PageSection
        paddingVertical={40}
        backgroundColor="black"
        backgroundOpacity="10"
        gap={20}
      >
        <DisplayTitle
          order={2}
          size={40}
        >
          {t('contact.title')}
        </DisplayTitle>

        {/* Contact options */}
        <Column.Container
          direction={{ desktop: FlexDirection.Row, mobile: FlexDirection.Column }}
        >
          <Column
            width={{ desktop: '50%', mobile: '100%' }}
            align={{ desktop: FlexAlign.Stretch }}
            justify={{ desktop: FlexJustify.Stretch }}
          >
            <MassiveIconButton
              icon={<IconPhoneCheck />}
              component='a'
              href='tel:+61 414 674 898'
              text='0414 674 898'
            />
          </Column>

          <Line.Adaptive
            orientation={{ desktop: 'vertical', mobile: 'horizontal' }}
            marginHorizontal={isMobile ? 0 : 5}
            marginVertical={isMobile ? 20 : 0}
          />

          <Column
            width={{ desktop: '50%', mobile: '100%' }}
            align={{ desktop: FlexAlign.Center }}
            justify={{ desktop: FlexJustify.Center }}
          >
            <MassiveIconButton
              icon={<IconMail />}
              component='a'
              href='mailto:rtft.cleaners@gmail.com'
              text='rtft.cleaners@gmail.com'
            />
          </Column>
        </Column.Container>

        <ContactForm />
      </PageSection>

      <Footer />
    </>
  )
}
