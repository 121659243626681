import { Group, Loader, Paper, Stack, Title } from "@mantine/core";
import { IconMail, IconMessage } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { sendContactEmail } from "../../../func/mailer";
import TextInput, { AutoComplete } from "../../../phosphorus/inputs/text/TextInput";
import { Textarea } from "../../../phosphorus/inputs/text/TextArea";
import Text from "../../../phosphorus/text/FormattedText";
import GradientButton from "../../../phosphorus/inputs/buttons/GradientButton";
import { DEFAULT_GRADIENT } from "../../COLORS";
import { EMAIL_REGEX } from "./_types";

export default function ContactForm() {

  // Hooks
  const { t } = useTranslation("mailingForms");

  // States
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  // Effects
  useEffect(() => {
    setEmailSent(sessionStorage.getItem("emailSent") === "true" ? true : false);
  }, []);

  // Send the email
  async function sendEmail() {
    let em = email.trim();
    let me = message.trim();

    // Ensure an email hasn"t already been sent
    if (emailSent) return;

    // Validate inputs
    if (!em || !me) {
      setAlert(t("alerts.noInputs"));
      return;
    }
    if (!EMAIL_REGEX.test(em)) {
      setAlert(t("alerts.invalidEmail"));
      return;
    }

    setAlert("");

    setLoading(true);

    // Send email
    let response = await sendContactEmail(em, me);
    setLoading(false);
    if (!response) {
      setAlert(t("alerts.error"));
      return;
    }

    setAlert(t("alerts.sent"))

    sessionStorage.setItem("emailSent", "true");
    setEmailSent(true);
  }

  return (
    <Paper
      shadow="md"
      p="md"
    >
      <Title
        order={3}
        style={{ marginBottom: 10 }}
      >
        {t("title")}
      </Title>

      <Stack
        spacing={5}
        align="flex-end"
      >
        <TextInput
          placeholder={t("emailInput")}
          icon={<IconMail />}
          autoComplete={AutoComplete.Email}
          style={{ width: "100%" }}
          disabled={loading}

          value={email}
          setValue={setEmail}
        />

        <Textarea
          placeholder={t("messageInput")}
          icon={<IconMessage />}
          style={{ width: "100%" }}
          disabled={loading}

          autosize
          minRows={5}

          value={message}
          setValue={setMessage}
        />

        <Group>
        <Text size="sm">
          {alert}
        </Text>

        {!loading ? null : <Loader size="sm" />}

        <GradientButton
          gradient={DEFAULT_GRADIENT}
          onClick={sendEmail}
          disabled={loading || emailSent}
        >
          {t("sendButton")}
        </GradientButton>
      </Group>
      </Stack>
    </Paper>
  )
}